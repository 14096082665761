<template>
  <div v-if="loaded">
    <div id="toolbar" class="padding-xy-3 padding-xy-3-tablet">
      <div class="columns">
        <div class="column">
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <div class="project-type tag" :class="type">
                {{ type }}
              </div>
              <span class="margin-x-3 has-text-white">{{ project.name }}</span>
              <span v-if="project.status === 'rejected' || project.status === 'canceled'">
                <span v-if="project.status === 'rejected'" class="has-text-danger">
                  <b-icon icon="ban" size="is-small" />
                </span>
                <span v-if="project.status === 'canceled'" class="has-text-danger">
                  <b-icon icon="backspace" size="is-small" /><small>Canceled</small>
                </span>
              </span>
              <span v-else class="margin-x-3 has-text-white">
                <b-tooltip v-if="project.invoice === 'pending' || project.invoice === 'in cycle' || project.invoice === 'unapproved'" size="is-large" position="is-right" label="In Cycle">
                  <b-icon icon="hourglass-half" class="has-text-grey-light" size="is-small" />
                </b-tooltip>
                <b-tooltip v-if="project.invoice === 'ready'" size="is-large" position="is-right" label="Ready">
                  <b-icon icon="file-minus" class="has-text-primary" size="is-small" />
                </b-tooltip>
                <b-tooltip v-if="project.invoice === 'unsent'" size="is-large" position="is-right" label="Invoiced">
                  <b-icon icon="file" class="has-text-success" size="is-small" />
                </b-tooltip>
                <b-tooltip v-if="project.invoice === 'sent'" size="is-large" position="is-right" label="Invoice Sent">
                  <b-icon icon="file-check" class="has-text-success" size="is-small" />
                </b-tooltip>
                <b-tooltip v-if="project.invoice === 'paid'" size="is-large" position="is-right" label="Paid">
                  <b-icon icon="dollar-sign" class="has-text-success" size="is-small" />
                </b-tooltip>
                <b-tooltip v-if="project.invoice === 'due'" size="is-large" position="is-right" label="Overdue">
                  <b-icon icon="dollar-sign" class="has-text-danger" size="is-small" />
                </b-tooltip>
              </span>
            </div>
            <div v-if="project.orderNumber" class="column is-narrow">
              <small v-if="newProject" class="has-text-grey-light">order #{{ (projectLicensee) ? projectLicensee.code : '' }}-{{ project.orderNumber }}</small>
              <small v-else class="has-text-grey-light">order #{{ fullOrderNumber }}</small>
            </div>
            <div v-if="!newProject" class="column is-narrow">
              <b-dropdown :mobile-modal="false" position="is-bottom-left">
                <b-button
                  slot="trigger"
                  rounded
                  type="is-primary"
                  icon-right="ellipsis-h"
                  class="icon-only" />
                <b-dropdown-item v-if="!project.invoicePaid && profile.type === 'jfe'" @click="confirmDeleteProject()">
                  Delete Project
                </b-dropdown-item>
                <b-dropdown-item v-if="!project.invoicePaid" @click="confirmCancelProject()">
                  Cancel Project
                </b-dropdown-item>
                <b-dropdown-item @click="duplicateProject()">
                  Duplicate Project
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row padding-y-3 padding-y-6-tablet">
      <div class="columns is-gapless">
        <div class="column is-5-tablet">
          <div class="padding-x-4 padding-x-5-tablet">
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-field class="required" label="Project Name" :type="{'is-danger': errors.has('name')}" :message="errors.first('name')">
                  <b-input :disabled="!canEdit" v-model="project.name" v-validate="'required'" name="name" maxlength="80" />
                </b-field>
                <b-field class="required" label="End User" :type="{'is-danger': errors.has('endUser')}" :message="errors.first('endUser')">
                  <b-input :disabled="!canEdit" v-model="project.endUser" v-validate="'required'" data-vv-as="end user" name="endUser" maxlength="80" />
                </b-field>
                <b-field v-show="profile.type === 'jfe'" class="required" label="Licensee" :type="{'is-danger': errors.has('licensee')}" disabled="true" :message="errors.first('licensee')">
                  <!-- <autocomplete-single
                    v-model="project.licenseeId"
                    v-validate="'required'"
                    class="field no-icon wrap-item"
                    :open-on-focus="true"
                    :items="licensees"
                    text-field="name"
                    value-field="id"
                    :disabled="!!project.invoiceCreateDate || id !== 'new'"
                    name="licensee" /> -->
                    <b-autocomplete
                      v-model="selectedLicenseeName"
                      v-validate="'required'"
                      class="field no-icon wrap-item"
                      open-on-focus
                      field="name"
                      :data="licenseesFiltered"
                      :disabled="!!project.invoiceCreateDate || id !== 'new'"
                      name="licensee"
                      :clearable="id === 'new'"
                      @select="onChangeLicensee" />
                </b-field>
                <b-field v-show="divisions.length > 0" class="required" label="Division" :type="{'is-danger': errors.has('division')}" :message="errors.first('division')">
                  <b-select :disabled="!canEdit" v-model="project.licenseeDivisionId" v-validate="{required: divisions.length > 0}" class="required" expanded name="division" placeholder="Select a division">
                    <option v-for="division in divisions" :key="division.id" :value="division.id">
                      {{ division.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Location">
                  <b-input :disabled="!canEdit" v-model="project.location" />
                </b-field>
              </div>
              <div class="column is-6">
                <b-field class="required" label="Start Date" :type="{'is-danger': errors.has('projectStartDate')}" :message="errors.first('projectStartDate')">
                  <b-datepicker v-model="projectStartDate" v-validate="'required'" icon="calendar-alt" :mobile-modal="false" name="projectStartDate" data-vv-as="start date" :disabled="project.invoiceSent == 1">
                    <div class="level is-mobile">
                      <button class="button has-text-grey is-transparent" @click="project.startDate = new Date()">
                        <b-icon icon="calendar-alt" />
                        <span>Today</span>
                      </button>
                      <button class="button has-text-grey is-transparent" @click="project.startDate = null">
                        <b-icon icon="times" />
                        <span>Reset</span>
                      </button>
                    </div>
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field class="required" label="End Date" :type="{'is-danger': errors.has('projectEndDate')}" :message="errors.first('projectEndDate')">
                  <b-datepicker v-model="projectEndDate" v-validate="'required'" icon="calendar-alt" :mobile-modal="false" name="projectEndDate" data-vv-as="end date" :disabled="project.invoiceSent == 1">
                    <div class="level is-mobile">
                      <button class="button has-text-grey is-transparent" @click="project.endDate = new Date()">
                        <b-icon icon="calendar-alt" />
                        <span>Today</span>
                      </button>
                      <button class="button has-text-grey is-transparent" @click="project.endDate = null">
                        <b-icon icon="times" />
                        <span>Reset</span>
                      </button>
                    </div>
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <hr>
            <div class="columns is-multiline">
              <!-- @change.native="option => (option) ? project.contactEmail = option.username : null" -->
              <div class="column is-12">
                <!-- <b-field label="Contact Name">
                  <autocomplete-single
                    v-model="project.contactName"
                    class="field"
                    :open-on-focus="true"
                    :items="usersByLicenseeId"
                    text-field="userFullName"
                    value-field="userFullName"
                    name="contactName"
                    @change="option => (option) ? project.contactEmail = option.username : null"
                    />
                </b-field> -->
                <b-field label="Contact Name">
                  <div v-if="project.licenseeId && usersContactFullName.length > 0" class="autocomplete-wrapper">
                    <b-autocomplete
                     :disabled="!canEdit"
                      v-model="project.contactName"
                      class="field"
                      :open-on-focus="true"
                      :data="usersContactFullName"
                      field="userFullName"
                      @select="option => (option) ? project.contactEmail = option.username : null" />
                    <div v-if="project.contactName" class="is-marginless autocomplete-clear" @click="clearContactName">
                      &times;
                    </div>
                  </div>
                  <div v-else>
                    <b-input v-model="project.contactName" />
                  </div>
                </b-field>
              </div>
              <div class="column is-12">
                <b-field label="Email">
                  <b-input :disabled="!canEdit" v-model="project.contactEmail" />
                </b-field>
              </div>
              <div class="column is-12">
                <b-field label="Phone">
                  <b-input :disabled="!canEdit" v-model="project.contactPhone" />
                </b-field>
              </div>
            </div>
            <hr>
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-field label="PO #">
                  <b-input :disabled="!canEdit" v-model="project.internalId" />
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="padding-xy-4 padding-x-5-desktop padding-y-0-tablet">
            <div class="card has-shadow-30 is-paddingless">
              <div v-if="newProject" class="padding-xy-3 padding-y-4-tablet padding-x-5-tablet">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <b-field class="required" label="Connection" :type="{'is-danger': errors.has('connection')}" :message="errors.first('connection')">
                      <b-select v-if="licenseeConnections" v-model="project.connectionId" v-validate="'required'" expanded name="connection" placeholder="Select a connection" @change.native="onChangeConnection()" :disabled="!project.licenseeId">
                        <option v-for="connection in connectionsFiltered" :key="connection.id" :value="connection.id">
                          {{ connection.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div v-show="showConnectionDetails" class="column is-12">
                    <div class="columns  is-multiline">
                      <div class="column is-6">
                        <b-field class="required" label="Size" :type="{'is-danger': errors.has('size')}" :message="errors.first('size')">
                          <b-select v-model="project.connectionSizeId" v-validate="'required'" expanded :disabled="!projectConnection" name="size" placeholder="Select a size" @change.native="onChangeSize()">
                            <template v-if="projectConnection">
                              <option v-for="connectionSize in projectConnection.sizes.filter(s => !s.deleted)" :key="connectionSize.id" :value="connectionSize.id">
                                {{ connectionSize.size }}"
                              </option>
                            </template>
                          </b-select>
                        </b-field>
                      </div>
                      <div class="column is-6">
                        <b-field class="required" label="Weight" :type="{'is-danger': errors.has('connectionWeight')}" :message="errors.first('connectionWeight')">
                          <b-select v-model="selectedWeight" v-validate="'required'" expanded :disabled="!projectConnectionSizeWeights" name="connectionWeight" data-vv-as="Select a weight">
                            <option v-for="connectionSizeWeight in projectConnectionSizeWeights" :key="connectionSizeWeight.id" :value="connectionSizeWeight.id">
                              {{ connectionSizeWeight.weight }}#
                            </option>
                          </b-select>
                        </b-field>
                      </div>
                      <div class="column is-6">
                        <b-field class="required" label="Material" :type="{'is-danger': errors.has('material')}" :message="errors.first('material')">
                          <b-select v-model="project.materialType" v-validate="'required'" :disabled="!projectConnectionSizeWeights" expanded placeholder="Select a material" name="material">
                            <option v-for="material in materialsType" :key="material.type" :value="material.type">
                              {{ material.name }}
                            </option>
                          </b-select>
                        </b-field>
                      </div>
                      <div v-show="project.materialType===1" class="column is-6">
                        <b-field class="required" label="Grade" :type="{'is-danger': errors.has('grade')}" :message="errors.first('grade')">
                          <b-select v-model="project.connectionGradeId" v-validate="{required: project.materialType===1}" expanded placeholder="Select a grade" name="grade">
                            <option v-for="connectionGrade in connectionGrades" :key="connectionGrade.id" :value="connectionGrade.id">
                              {{ connectionGrade.name }}
                            </option>
                          </b-select>
                        </b-field>
                      </div>
                      <div v-show="project.materialType===2" class="column is-6">
                        <b-field class="required" label="CRA Description" :type="{'is-danger': errors.has('craDescription')}" :message="errors.first('craDescription')">
                          <b-input v-model="project.craDescription" v-validate="{required: project.materialType===2}" expanded name="craDescription" />
                        </b-field>
                      </div>
                      <div v-show="project.materialType===3" class="column is-6">
                        <b-field class="required" label="Description" :type="{'is-danger': errors.has('craDescription')}" :message="errors.first('craDescription')">
                          <b-input v-model="project.craDescription" v-validate="{required: project.materialType===3}" expanded name="craDescription" />
                        </b-field>
                      </div>
                      <div class="column" :class="{ 'is-12': type !== 'manufacturing', 'is-6': type === 'manufacturing' }">
                        <b-field label="Threading">
                          <b-select v-model="project.connectionThreading" expanded>
                            <option>Clear-Run</option>
                            <option>API Modified</option>
                          </b-select>
                        </b-field>
                      </div>
                      <div v-if="type === 'manufacturing'" class="column">
                        <b-field label="Manufacturer (mill)">
                          <b-input v-model="project.manufacturer" />
                        </b-field>
                      </div>
                      <div v-if="type === 'manufacturing'" class="column is-6">
                        <b-field :class="{ 'required' : !!(type === 'manufacturing') }" label="Joint Length" :type="{'is-danger': errors.has('jointLength')}" :message="errors.first('jointLength')">
                          <b-select v-model="project.jointLength" v-validate="'required'" expanded name="jointLength" placeholder="Choose...">
                            <template v-if="projectConnection">
                              <option :value="30">30'</option>
                              <option :value="40">40'</option>
                            </template>
                          </b-select>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="padding-xy-3 padding-xy-4-tablet" style="padding-bottom: 0">
                <table class="table is-fullwidth">
                  <tr>
                    <td>Connection</td>
                    <td class="has-text-right">
                      <strong>{{ projectConnection ? projectConnection.name : null }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Size</td>
                    <td v-if="projectConnectionSize" class="has-text-right">
                      <strong>{{ projectConnectionSize ? projectConnectionSize.size : null }}"</strong>
                    </td>
                    <td v-else class="is-danger">
                      <b-tooltip type="is-dark" multilined size="is-large" position="is-top">
                        <span>Not set</span>
                        <template v-slot:content>
                          <span v-html="'This project’s data has become corrupted. Please delete and recreate it.'" />
                        </template>
                      </b-tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td class="has-text-right">
                      <strong>{{ project.connectionWeight }}#</strong>
                    </td>
                  </tr>
                  <tr v-if="project.materialType===3">
                    <td>Other Material</td>
                    <td class="has-text-right">
                      <strong>{{ projectConnectionGrade }}</strong>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>Grade</td>
                    <td class="has-text-right">
                      <strong>{{ projectConnectionGrade }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Threading</td>
                    <td class="has-text-right">
                      <strong>{{ project.connectionThreading }}</strong>
                    </td>
                  </tr>
                  <tr v-if="type === 'manufacturing'">
                    <td>Manufacturer</td>
                    <td class="has-text-right">
                      <strong>{{ project.manufacturer }}</strong>
                    </td>
                  </tr>
                  <tr v-if="type === 'manufacturing' && project.jointLength">
                    <td>Joint Length</td>
                    <td class="has-text-right">
                      <strong>{{ project.jointLength }}'</strong>
                    </td>
                  </tr>
                  <tr v-if="type === 'manufacturing' && project.jointLength">
                    <td>Tonnage</td>
                    <td class="has-text-right">
                      <strong>{{ tonnage }}</strong>
                    </td>
                  </tr>
                </table>
              </div>

              <hr v-show="newProject && showConnectionDetails" class="margin-y-1">

              <div v-if="showConnectionDetails" class="padding-xy-3 padding-y-4-tablet padding-x-5-tablet">
                <div v-if="type === 'manufacturing'" class="columns">
                  <div class="column">
                    <b-field :class="{ 'required' : !jointsDisabled}" label="Joints" :type="{'is-danger': errors.has('joints')}" :message="errors.first('joints')">
                      <b-input v-model.number="project.joints" v-validate="{required: !jointsDisabled, numeric: true, min_value:0}" name="joints" type="number" :disabled="jointsDisabled || !canEdit" />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field :class="{ 'required' : !couplingsDisabled}" label="Couplings" :type="{'is-danger': errors.has('couplings')}" :message="errors.first('couplings')">
                      <b-input v-model.number="project.couplings" v-validate="{required: !couplingsDisabled, numeric: true, min_value:0}" name="couplings" type="number" :disabled="couplingsDisabled || !canEdit" />
                    </b-field>
                  </div>
                <!-- <div class="column">
                    <b-field label="Total Ends" :type="{'is-danger': errors.has('totalEnds')}" :message="errors.first('totalEnds')">
                      <b-input v-model.number="project.totalEnds" v-validate="'required|numeric|min_value:0'" name="totalEnds" type="number" />
                    </b-field>
                  </div> -->
                </div>
                <div v-else class="columns">
                  <div class="column">
                    <b-field :class="{ 'required' : !jointsDisabled}" label="Pins" :type="{'is-danger': errors.has('pins')}" :message="errors.first('pins')">
                      <b-input v-model.number="project.pins" v-validate="{required: !jointsDisabled, numeric: true, min_value:0}" name="pins" type="number" :disabled="jointsDisabled || !canEdit" />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field :class="{ 'required' : !couplingsDisabled}" label="Boxes" :type="{'is-danger': errors.has('boxes')}" :message="errors.first('boxes')">
                      <b-input v-model.number="project.boxes" v-validate="{required: !couplingsDisabled, numeric: true, min_value:0}" name="boxes" type="number" :disabled="couplingsDisabled || !canEdit" />
                    </b-field>
                  </div>
                </div>
              </div>
              <hr class="margin-y-0">
              <div class="has-background-white-ter">
                <div class="columns is-mobile has-text-centered is-marginless">
                  <div class="column">
                    <b class="is-uppercase is-size-7 has-text-grey">Units</b>
                    <div class="is-size-5 has-text-weight-light">
                      {{ units }}
                    </div>
                  </div>
                  <div v-if="type === 'manufacturing'" class="column">
                    <b class="is-uppercase is-size-7 has-text-grey">Tonnage</b>
                    <div class="is-size-5 has-text-weight-light">
                      {{ tonnage }}
                    </div>
                  </div>
                  <div v-if="!requestNewRate" class="column hover-rate" v-on="showRequestButton && canEdit ? { click: () => showRequestNewRate() } : ''">
                    <b class="is-uppercase is-size-7 has-text-grey">Rate</b>
                    <div class="is-size-5 has-text-weight-light">
                      <span v-if="rate">{{ rate | currency }}</span><span v-else>--</span>
                    </div>
                    <div v-if="project.connectionNewRateStatus === 1" class="is-size-6 has-text-weight-bold">
                      <hr class="has-background-grey-lighter margin-y-2"><span>Requested:<br>{{ project.connectionNewRate | currency }}</span>
                    </div>
                  </div>
                  <div v-else class="column">
                    <b-field
                      :class="{ 'required' : requestNewRate}"
                      :label="profile.type === 'jfe' ? 'Rate Override' : 'Requested Rate'"
                      :type="{'is-danger': errors.has('connectionNewRate')}"
                      :message="{'A new rate value is required.' : errors.first('connectionNewRate')}"
                      style="margin-bottom: 7px">
                      <div class="body">
                        <b-field>
                          <p class="control">
                            <b-button class="is-static">
                              $
                            </b-button>
                          </p>
                          <b-input v-model.number="project.connectionNewRate" v-validate="{required: requestNewRate, min_value:0}" name="connectionNewRate" :disabled="!requestNewRate" />
                        </b-field>
                      </div>
                    </b-field>
                    <div class="level is-mobile">
                      <b-button size="is-small" @click="requestNewRate = false">
                        <span>Cancel</span>
                      </b-button>
                      <b-button size="is-small" type="is-primary" @click="saveRequestNewRate()">
                        <span v-if="!saveNewRateButton">Request</span>
                        <span v-else>Save</span>
                      </b-button>
                    </div>
                  </div>
                  <div v-if="!requestNewRate" class="column">
                    <b class="is-uppercase is-size-7 has-text-grey">Total Royalty</b>
                    <div class="is-size-5 has-text-weight-light">
                      <span v-if="royalty">{{ royalty | currency }}</span><span v-else>--</span>
                    </div>
                    <div v-if="project.connectionNewRateStatus === 1" class="is-size-6 has-text-weight-bold">
                      <hr class="has-background-grey-lighter margin-y-2"><span>Requested:<br>{{ newRoyalty | currency }}</span>
                    </div>
                  </div>
                  <div v-else class="column">
                    <b class="is-uppercase is-size-7 has-text-grey">New Total Royalty</b>
                    <div class="is-size-5 has-text-weight-light">
                      <span v-if="newRoyalty">{{ newRoyalty | currency }}</span><span v-else>--</span>
                    </div>
                  </div>
                </div>

                <div v-if="project.connectionNewRateStatus === 1" class="has-text-centered" style="margin-bottom: 15px">
                  <b-button v-if="profile.type !== 'jfe'" size="is-small" type="is-warning" @click="cancelRequestNewRate()">
                    <span>Cancel Rate Request</span>
                  </b-button>

                  <div v-if="profile.type === 'jfe' && !newProject">
                    <b-button type="is-danger" @click="rejectRequestNewRate()">
                      <span>Reject New Rate</span>
                    </b-button>
                    <span class="margin-x-2" />
                    <b-button type="is-success" @click="approveRequestNewRate()">
                      <span>Approve New Rate</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <hr class="margin-y-0">
              <div class="padding-xy-3 padding-y-4-tablet padding-x-5-tablet">
                <b-field label="Project Description">
                  <b-input :disabled="!canEdit" v-model="project.description" type="textarea" rows="3" />
                </b-field>
                <template>
                  <div v-if="profile.type === 'jfe' && !newProject && type !== 'accessory'" class="columns is-vcentered is-mobile">
                    <div class="column">
                      <b-button class="is-fullwidth" rounded size="is-medium" :class="(project.status === null ? 'is-danger is-outlined' : (project.status ==='rejected' ? 'is-danger' : 'is-outlined has-text-grey-light'))" :disabled="project.connectionNewRateStatus === 1" @click="project.status = 'rejected'">
                        <span>Reject<span v-if="project.status === 'rejected'">ed</span></span>
                      </b-button>
                    </div>
                    <div class="column">
                      <b-button rounded type=" is-fullwidth" size="is-medium" :class="(project.status === null ? 'is-success is-outlined' : (project.status ==='accepted' ? 'is-success' : 'is-outlined has-text-grey-light'))" :disabled="project.connectionNewRateStatus === 1" @click="project.status = 'accepted'">
                        <span>Approve<span v-if="project.status === 'accepted'">d</span></span>
                      </b-button>
                    </div>
                  </div>
                </template>
              </div>
              <div class="card-footer">
                <template v-if="type !== 'accessory'">
                  <template v-if="profile.type === 'jfe'">
                    <b-button :disabled="!canEdit" type="is-primary is-medium" style="width: 100%" @click="save">
                      <span>Save</span>
                    </b-button>
                  </template>
                  <template v-else>
                    <template v-if="!project.status">
                      <b-button v-if="!project.id" type="is-primary" style="width: 100%" @click="save">
                        <span>Request</span>
                      </b-button>
                      <b-button v-if="project.id" type="is-danger" style="width: 50%" @click="cancelRequest">
                        <span>Cancel Request</span>
                      </b-button>
                      <b-button :disabled="!canEdit" v-if="project.id" type="is-primary" style="width: 100%" @click="save">
                        <span>Save</span>
                      </b-button>
                    </template>
                    <template v-else>
                      <b-button class="is-transparent is-size-5" style="width: 100%; cursor: default;">
                        <span v-if="project.status === 'rejected'" class="has-text-danger">Project Rejected</span>
                        <span v-else-if="project.status === 'canceled'" class="has-text-danger">Project Canceled</span>
                        <span v-else-if="project.deleted" class="has-text-danger">Project Deleted</span>
                        <span v-else class="is-success">Project Approved</span>
                      </b-button>
                      <b-button :disabled="!canEdit" v-if="project.id" type="is-primary" style="width: 100%" @click="save">
                        <span>Save</span>
                      </b-button>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <div v-if="project.status === 'canceled'" class="has-text-danger has-text-weight-bold has-text-centered padding-xy-3" style="width: 100%">
                    Project Canceled
                  </div>
                  <div v-else-if="project.deleted" class="has-text-danger has-text-weight-bold has-text-centered padding-xy-3" style="width: 100%">
                    Project Deleted
                  </div>
                  <b-button v-else :disabled="!canEdit" type="is-primary is-medium" style="width: 100%" @click="save">
                    <span>Save</span>
                  </b-button>
                </template>
                <b-button v-if="project.invoiceCreateDate" type="is-success" style="width: 100%" @click="goToInvoice">
                  <span>View Invoice</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Moment from 'moment'
import EmailsApi from '@/apis/EmailsApi'
import Vue2Filters from 'vue2-filters'
import ProjectsApi from '@/apis/ProjectsApi'
import SendNotificationModal from '@/components/modals/SendNotification'
import ProjectsUtil from '@/utils/ProjectsUtil'
import AdminApi from '@/apis/AdminApi'

export default {
  name: 'Project',
  components: {
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      project: {
        type: null,
        name: null,
        endUser: null,
        description: null,
        location: null,
        startDate: null,
        endDate: null,
        contactName: null,
        contactEmail: null,
        contactPhone: null,
        internalId: null,
        licenseeId: null,
        licenseeDivisionId: null,
        connectionId: null,
        connectionSizeId: null,
        connectionGradeId: null,
        materialType: null,
        craDescription: null,
        connectionWeight: null,
        connectionThreading: null,
        manufacturer: null,
        pins: null,
        boxes: null,
        joints: null,
        couplings: null,
        totalEnds: null,
        royalty: null,
        total: null,
        status: null,
        sentNotification: false
      },
      notificationEmail: {
        emailTo: {
          email: null,
          name: null
        },
        subject: null,
        textPart: null,
        body: null
      },
      jointsDisabled: true,
      requestNewRate: false,
      saveNewRateButton: false,
      couplingsDisabled: true,
      showConnectionDetails: false,
      newProject: false,
      loaded: false,
      fatorNumber: 100000000,
      materialsType: [{ type: 1, name: 'Standard Grade' }, { type: 2, name: 'CRA' }, { type: 3, name: 'Other' }],
      oldProjectStatus: null,
      selectedWeight: null,
      selectedLicenseeName: null,
      licensee: null,
      projectConnection: null,
      licenseeConnection: null,
      licenseeConnections: null
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    type () {
      return this.$route.params.type
    },
    offices () {
      return this.$store.state.offices
    },
    users () {
      return this.$store.state.users
    },
    statusRate () {
      switch (this.project.connectionNewRateStatus) {
        case 1:
          return 'Pending'
        case 2:
          return 'Approved'
        case 3:
          return 'Rejected'
        default:
          return null
      }
    },
    showRequestButton () {
      if (this.rate) {
        if (this.profile.type === 'jfe' || this.licensee.canRequestSpecialRate) {
          switch (this.project.connectionNewRateStatus) {
            case 1:
            case 3:
              return true
            case 2:
              return true
            default:
              return true
          }
        }
      }

      return false
    },
    usersByLicenseeOfficeId () {
      if (this.project.licenseeId) {
        return this.users.filter(u => u.officeId === this.licensee.officeId && u.enabled && !u.deleted)
      } else {
        return null
      }
    },
    weightById () {
      const mapped = {}

      this.projectConnectionSizeWeights.forEach(weight => {
        mapped[weight.id] = weight
      })

      return mapped
    },
    usersByLicenseeId () {
      if (this.project.licenseeId) {
        return this.users.filter(u => u.licenseeId === this.project.licenseeId && u.enabled && !u.deleted)
      } else {
        return null
      }
    },
    fullOrderNumber () {
      if (this.id === 'new') {
        return this.projectLicensee.code + '-' + this.project.orderNumber
      } else {
        return this.project.orderNumber
      }
    },
    divisions () {
      let licenseeSelected = this.$store.state.licensees.filter(licensee => licensee.id === this.project.licenseeId)

      if (licenseeSelected.length > 0) {
        if (this.profile.type === 'jfe') {
          return licenseeSelected[0].divisions
        } else if (this.profile.type === 'licensee') {
          if (this.profile.divisions) {
            if (this.profile.divisions.length === 0) { // Has all divisions
              return licenseeSelected[0].divisions
            } else {
              return licenseeSelected[0].divisions.filter(division => this.profile.divisions.includes(division.id))
            }
          } else {
            return []
          }
        }
      }

      return []
    },
    licensees () {
      if (this.profile.type === 'jfe') {
        if (this.profile.hasJfeAdminPermission) {
          return this.orderBy(this.$store.state.licensees.filter(licensee => !licensee.suspended || licensee.id === this.project.licenseeId), 'name')
        } else {
          return this.orderBy(this.$store.state.licensees.filter(licensee => licensee.officeId === this.profile.officeId).filter(licensee => !licensee.suspended || licensee.id === this.project.licenseeId), 'name')
        }
      } else if (this.profile.type === 'licensee') {
        return this.orderBy(this.$store.state.licensees.filter(licensee => licensee.id === this.profile.licenseeId).filter(licensee => !licensee.suspended || licensee.id === this.project.licenseeId), 'name')
      } else {
        return []
      }
    },
    connections () {
      if (this.id === 'new') {
        return this.$store.state.connectionsLight.filter(c => c.royaltyAvailability)
      } else {
        return this.$store.state.connectionsLight
      }
    },
    // connectionById () {
    //   const mapped = {}

    //   this.connections.forEach(connection => {
    //     mapped[connection.id] = connection
    //   })

    //   return mapped
    // },
    licenseesFiltered () {
      return this.licensees.filter(l => {
        return (
          l.name.toString().toLowerCase().indexOf(this.selectedLicenseeName.toLowerCase()) >= 0
        )
      })
    },
    connectionsFiltered () {
      return this.connections.filter(connection => {
        var licenseeConnection = this.licenseeConnections ? this.licenseeConnections.find(lc => (lc.licenseeId === this.project.licenseeId) && (lc.connectionId === connection.id)) : null
        if (licenseeConnection) {
          if (this.type === 'manufacturing') {
            return licenseeConnection.manufacture
          } else if (this.type === 'repair') {
            return licenseeConnection.repair
          } else if (this.type === 'accessory') {
            return licenseeConnection.accessory
          } else {
            return false
          }
        } else {
          return false
        }
      })
    },
    connectionGrades () {
      let hiddenGrades = []
      if (this.newProject && this.selectedWeight && this.weightById[this.selectedWeight]) {
        if (this.weightById[this.selectedWeight].hiddenGrades) {
          hiddenGrades = this.weightById[this.selectedWeight].hiddenGrades.split(',')
        }
        return this.$store.state.connectionGrades.filter(g => !hiddenGrades.includes(String(g.id))).filter(grade => grade.public)
      }

      return this.$store.state.connectionGrades
    },
    projectConnectionSizeWeights () {
      if (this.project.connectionSizeId) {
        var size = this.projectConnection ? this.projectConnection.sizes.find(size => size.id === this.project.connectionSizeId) : null

        if (size == null) {
          return []
        } else {
          return size.weights
        }
      } else {
        return []
      }
    },
    projectLicensee () {
      if (this.project.licenseeId) {
        return this.licensees.find(c => c.id === this.project.licenseeId)
      } else {
        return null
      }
    },
    projectConnectionSize () {
      if (this.project.connectionId && this.projectConnection) {
        return this.projectConnection.sizes.find(size => size.id === this.project.connectionSizeId)
      } else {
        return null
      }
    },
    projectConnectionGrade () {
      if (this.project.connectionId) {
        switch (this.project.materialType) {
          case 1: {
            const hidenGrade = this.connectionGrades.find(c => c.id === this.project.connectionGradeId)
            if (hidenGrade) {
              return hidenGrade.name
            }
            return ''
          }
          case 2:
          case 3:
            return this.project.craDescription
          default:
            return null
        }
      } else {
        return null
      }
    },
    usersContactFullName () {
      if (this.project.licenseeId) {
        return this.usersByLicenseeId.map(u => {
          u.userFullName = u.firstName + ' ' + u.lastName
          return u
        })
      } else {
        return null
      }
    },
    projectStartDate: {
      get () {
        return this.project.startDate ? Moment(this.project.startDate).toDate() : null
      },
      set (val) {
        this.project.startDate = Moment(val).format('YYYY-MM-DD')
      }
    },
    projectEndDate: {
      get () {
        return this.project.endDate ? Moment(this.project.endDate).toDate() : null
      },
      set (val) {
        this.project.endDate = Moment(val).format('YYYY-MM-DD')
      }
    },
    profile () {
      return this.$store.state.profile
    },
    units () {
      if (this.type === 'manufacturing') {
        return (this.project.joints || 0) + (this.project.couplings || 0)
      } else {
        return (this.project.pins || 0) + (this.project.boxes || 0)
      }
    },
    tonnage () {
      if (this.project.connectionWeight) {
        return this.project.joints && this.project.jointLength && this.project.connectionWeight ? this.numberFormat(((this.project.joints * this.project.jointLength * this.project.connectionWeight) / 2205), 2) : '--'
      } else {
        return this.project.joints && this.project.jointLength && this.weightById[this.selectedWeight].weight ? this.numberFormat(((this.project.joints * this.project.jointLength * this.weightById[this.selectedWeight].weight) / 2205), 2) : '--'
      }
    },
    rate () {
      if (this.project.connectionRate > 0) {
        return this.project.connectionRate
      } else {
        if (this.projectConnection && this.project.connectionSizeId && ((this.project.connectionGradeId) || (this.project.materialType)) && this.licenseeConnection) {
          let field = null
          switch (this.project.materialType) {
            // case 1:
            //   let grade = this.connectionGrades.find(grade => grade.id === this.project.connectionGradeId)

            //   if (this.type === 'manufacturing') {
            //     field = grade && grade.chrome ? 'priceManCra' : 'priceManNon'
            //   } else if (this.type === 'repair') {
            //     field = grade && grade.chrome ? 'priceRepCra' : 'priceRepNon'
            //   } else if (this.type === 'accessory') {
            //     field = grade && grade.chrome ? 'priceAccCra' : 'priceAccNon'
            //   }
            //   break
            case 2:
              if (this.type === 'manufacturing') {
                field = 'priceManCra'
              } else if (this.type === 'repair') {
                field = 'priceRepCra'
              } else if (this.type === 'accessory') {
                field = 'priceAccCra'
              }
              break
            case 1:
            case 3:
              if (this.type === 'manufacturing') {
                field = 'priceManNon'
              } else if (this.type === 'repair') {
                field = 'priceRepNon'
              } else if (this.type === 'accessory') {
                field = 'priceAccNon'
              }
              break
            default:
              return null
          }
          // Get the default values set into the connection screen
          const defaultSize = this.projectConnection.sizes.find(size => size.id === this.project.connectionSizeId)
          // There is any override value?
          const licenseeSize = this.licenseeConnection ? this.licenseeConnection.sizes.find(size => size.connectionSizeId === this.project.connectionSizeId && size.licenseeId === this.project.licenseeId) : null

          if (licenseeSize && defaultSize && this.project.materialType !== 3) {
            return licenseeSize[field] || defaultSize[field]
          } else if (defaultSize) {
            return defaultSize[field]
          }
        }

        return null
      }
    },
    royalty () {
      if (this.rate && this.units) {
        return this.rate * this.units
      }

      return null
    },
    newRoyalty () {
      if (this.project.connectionNewRate && this.units) {
        return this.project.connectionNewRate * this.units
      }

      return null
    },
    total () {
      if (this.projectLicensee && this.projectLicensee.taxWithholdingPercent) {
        const withholding = this.royalty * this.projectLicensee.taxWithholdingPercent / 100

        return this.royalty + withholding
      } else {
        return this.royalty
      }
    },
    canEdit () {
      return !this.project.invoiceCreateDate
      // let projectStatus = ProjectsUtil.getProjectInvoiceStatus(this.project)
      // switch (projectStatus) {
      //   case 'due':
      //   case 'paid':
      //   case 'sent':
      //   case 'unsent':
      //     return false
      //   default:
      //     return true
      // }
    }
  },
  watch: {
    'project.materialType' () {
      if (this.newProject) {
        switch (this.project.materialType) {
          case 1:
            this.project.craDescription = null
            break
          case 2:
            this.project.connectionGradeId = null
            break
          default:
            this.project.connectionGradeId = null
            this.project.craDescription = null
        }
      }
    }
  },
  async mounted () {
    this.$store.commit('setApp', 'royalty')
    this.$store.commit('setTitle', null)

    await this.$store.dispatch('refreshUsers')
    await this.$store.dispatch('refreshOffices')
    await this.$store.dispatch('refreshConnectionGrades')

    if (this.id === 'new') {
      // Following this order
      // 1-Get ALL the connections
      await this.$store.dispatch('refreshConnectionsLight', { hasImages: false, hasSizes: true, hasWeights: true })
      // 2-Get ALL the licensees
      let fields = {}

      switch (this.type) {
        case 'manufacturing':
          fields.manufacture = 1
          break
        case 'repair':
          fields.repair = 1
          break
        case 'accessory':
          fields.accessory = 1
          break
        default:
          break
      }

      await this.$store.dispatch('refreshLicensees', fields)
      this.$store.commit('setTitle', 'New Project')
      this.newProject = true

      if (this.profile.type === 'jfe') {
        if (this.licensees.length > 0) {
          // Get any cached values
          let userCached = JSON.parse(window.localStorage.getItem(this.profile.username)) || null
          // If has the licensee cahced Id, set it. Else, Set the First one.
          this.project.licenseeId = userCached && userCached.cachedSelectedLicenseeId && this.licensees.find(l => l.id === userCached.cachedSelectedLicenseeId) ? userCached.cachedSelectedLicenseeId : this.licensees[0].id

          if (this.licensees[0].divisions.length > 0) {
            this.project.licenseeDivisionId = this.licensees[0].divisions[0].id
          } else {
            this.project.licenseeDivisionId = null
          }
        } else {
          this.project.licenseeId = null
        }
        // this.project.licenseeId = null
      } else {
        this.project.licenseeId = this.profile.licenseeId
        this.project.licenseeDivisionId = this.profile.licenseeDivisionId
      }
      // Generate Preview Order Number
      var lastProject = await ProjectsApi.getLastProjectID()
      this.project.orderNumber = this.convertBase((lastProject.id + 1) + this.fatorNumber).toUpperCase()

      document.title = 'JFE Tools • New ' + this.type + ' project'
    } else {
      this.project = await ProjectsApi.getProjectById(this.id)
      this.project.invoice = ProjectsUtil.getProjectInvoiceStatus(this.project)

      let fields = {}
      fields.id = this.project.licenseeId // Load licensees array just with one licensee. This make works licensees computed
      await this.$store.dispatch('refreshLicensees', fields)

      this.newProject = false
      this.showConnectionDetails = true
      this.oldProjectStatus = this.project.status
      this.$store.commit('setTitle', this.project.name)
      document.title = 'JFE Tools • ' + this.project.name
    }

    if (this.project.licenseeId) {
      this.licensee = await AdminApi.getLicenseeById(this.project.licenseeId)
      await this.loadLicenseeConnections(this.project.licenseeId)
      this.selectedLicenseeName = this.licensee.name
    }

    // Here we need to check if the saved division is into the licensees group
    if (!this.divisions.find(d => d.id === this.project.licenseeDivisionId)) {
      this.project.licenseeDivisionId = null
    }

    this.refreshProjectConnection()

    this.loaded = true
  },
  methods: {
    async loadLicenseeConnections (licenseeId = null) {
      let fields = {
        licenseeId: licenseeId || this.project.licenseeId
      }
      switch (this.type) {
        case 'manufacturing':
          fields.manufacture = 1
          break
        case 'repair':
          fields.repair = 1
          break
        case 'accessory':
          fields.accessory = 1
          break
        default:
          break
      }

      this.licenseeConnections = await AdminApi.getLicenseeConnections(fields)
    },
    setPinBoxVisibility () {
      if (this.project.connectionId && this.project.licenseeId) {
        this.licenseeConnection = this.licenseeConnections.find(lc => lc.connectionId === this.project.connectionId && lc.licenseeId === this.project.licenseeId)
        if (this.type === 'manufacturing') {
          this.jointsDisabled = !this.licenseeConnection.manufacturePin
          this.couplingsDisabled = !this.licenseeConnection.manufactureBox
        } else if (this.type === 'repair') {
          this.jointsDisabled = !this.licenseeConnection.repairPin
          this.couplingsDisabled = !this.licenseeConnection.repairBox
        } else if (this.type === 'accessory') {
          this.jointsDisabled = !this.licenseeConnection.accessoryPin
          this.couplingsDisabled = !this.licenseeConnection.accessoryBox
        }
      }
    },
    async onChangeLicensee (licensee) {
      this.showConnectionDetails = false
      this.project.licenseeId = licensee ? licensee.id : null
      this.licenseeConnection = null

      if (this.project.licenseeId) {
        this.licensee = await AdminApi.getLicenseeById(this.project.licenseeId)
        this.selectedLicenseeName = this.licensee.name
      } else {
        this.licensee = null
      }

      if (this.id === 'new' || !this.project.licenseeId) {
        this.project.connectionId = null
        this.project.licenseeDivisionId = null
      }

      if (this.divisions.length === 1) {
        this.project.licenseeDivisionId = this.divisions[0].id
      }

      await this.loadLicenseeConnections()
    },
    onChangeConnection () {
      this.project.joints = null
      this.project.couplings = null
      this.project.pins = null
      this.project.boxes = null

      if (this.id === 'new' || !this.project.licenseeId) {
        this.selectedWeight = null
        this.project.connectionSizeId = null
        this.project.materialType = null
      }

      if (this.project.connectionId) {
        this.showConnectionDetails = true
        this.licenseeConnection = this.licenseeConnections.find(lc => lc.connectionId === this.project.connectionId && lc.licenseeId === this.project.licenseeId)
        if (this.licenseeConnection) {
          this.setPinBoxVisibility()
          // Restart values
          if (this.jointsDisabled) {
            this.project.joints = null
          }
          if (this.couplingsDisabled) {
            this.project.couplings = null
          }
        }
      } else {
        this.showConnectionDetails = false
        // this.project.materialType = null
        // this.selectedWeight = null
      }

      this.refreshProjectConnection()
    },
    onChangeSize () {
      this.selectedWeight = null
    },
    showRequestNewRate () {
      // this.project.connectionNewRate = this.project.connectionRate
      if ((this.profile.type === 'jfe') || (this.licensee.canRequestSpecialRate)) {
        this.requestNewRate = true
        this.saveNewRateButton = true
      }
    },
    clearContactName () {
      this.project.contactName = null
      this.project.contactEmail = null
    },
    async sendMultipleEmails (subject, body, usersNotification) {
      for (const user of usersNotification) {
        await this.sendEmailToUser(subject, body, user).then((mailResult) => {
          if (mailResult.Status !== undefined) {
            if (mailResult.Status === 'success') {
              // Do something?
            } else {
              console.log('#201 Error sending email:' + mailResult.ErrorMessage)
            }
          } else {
            console.log('#202 Error sending email:' + JSON.stringify(mailResult.ErrorMessage))
          }
        })
      }
    },
    async saveRequestNewRate () {
      if (await this.$validator.validateAll()) {
        this.project.connectionNewRateStatus = 1 // Requested
        if (this.type === 'accessory') {
          this.project.status = 'accepted'
        } else {
          this.project.status = null // Pending project again
        }
        this.requestNewRate = false

        if (this.id !== 'new') {
          this.project = await ProjectsApi.updateProject(this.project.id, this.project)

          if (this.profile.type !== 'jfe') {
            //= ==========================================
            // SEND NOTIFICATIONS
            //= ==========================================
            const loadingComponent = this.$buefy.loading.open({ container: null })
            try {
              const projectURL = process.env.VUE_APP_WEB_BASE + '/royalty_tracker/project/' + this.type + '/' + this.project.id
              const subject = 'New rate requested for project ' + this.project.name
              const body = '<p>A new rate has been requested for the following project:</p>' +
                '<p><strong>Project:</strong><a href="' + projectURL + '">' + this.project.name + '</a>' +
                '<br><strong>Requested rate: $' + this.project.connectionNewRate + '</strong>' +
                '<br><strong>Original rate: $' + this.project.connectionRate + '</strong>' +
                '<br><strong>Total Units:</strong> ' + this.units +
                '</p><p>To review this thread request, click here: <a href="' + projectURL + '">' + this.project.name + '</a></p>'

              var usersNotification = []
              this.usersByLicenseeOfficeId.map(u => {
                if (u.hasJfeAdminPermission) {
                  usersNotification.push(u)
                }
              })
              this.sendMultipleEmails(subject, body, [])
            } catch (e) {
              console.error(e)
            } finally {
              loadingComponent.close()
              this.$buefy.toast.open('Sent request')
            }
          }
        }
      }
    },
    async approveRequestNewRate () {
      if (await this.$validator.validateAll()) {
        this.requestNewRate = false
        this.saveNewRateButton = false
        this.project.connectionNewRateStatus = 2 // Approved
        this.project.connectionRate = this.project.connectionNewRate
        this.project.royalty = this.newRoyalty
        // this.project.connectionNewRate = null
        this.project = await ProjectsApi.updateProject(this.project.id, this.project)
        //= ==========================================
        // SEND NOTIFICATIONS
        //= ==========================================
        const loadingComponent = this.$buefy.loading.open({ container: null })
        try {
          const projectURL = process.env.VUE_APP_WEB_BASE + '/royalty_tracker/project/' + this.type + '/' + this.project.id
          const subject = 'New rate accepted for project ' + this.project.name
          const body = 'Your submitted request was approved:' +
            '<p><strong>Project:</strong>' + this.project.name +
            '<br><strong>New rate accepted: $' + this.project.connectionNewRate + '</strong>' +
            '<br><strong>Connection:</strong>' + this.projectConnection.name +
            '<br><strong>Size:</strong> ' + this.projectConnectionSize.size +
            '<br><strong>Weight:</strong> ' + this.project.connectionWeight + '#' +
            '<br><strong>Material:</strong> ' + this.materialsType.find(m => m.type === this.project.materialType).name +
            '<br><strong>Total Units:</strong> ' + this.units +
            '</p><p>To review this thread request, click here: <a href="' + projectURL + '">' + this.project.name + '</a></p>'

          var usersNotification = []
          this.usersByLicenseeId.map(u => {
            if (u.hasJfeAdminPermission) {
              usersNotification.push(u)
            }
          })

          this.sendMultipleEmails(subject, body, usersNotification)
        } catch (e) {
          console.error(e)
        } finally {
          loadingComponent.close()
          this.$buefy.toast.open('New rate saved')
        }
      }
    },
    async cancelRequestNewRate () {
      this.project.connectionNewRateStatus = null
      this.project.connectionNewRate = null
      this.requestNewRate = false

      if (this.id !== 'new') {
        this.project = await ProjectsApi.updateProject(this.project.id, this.project)
        //= ==========================================
        // SEND NOTIFICATIONS
        //= ==========================================
        const loadingComponent = this.$buefy.loading.open({ container: null })
        try {
          const projectURL = process.env.VUE_APP_WEB_BASE + '/royalty_tracker/project/' + this.type + '/' + this.project.id
          const subject = 'Canceled: New rate requested for project ' + this.project.name
          const body = 'This request was canceled by the user. No action is necessary this time.' +
            '<p><strong>Project:</strong>' + this.project.name +
            '<br><strong>Requested rate: $' + this.project.connectionNewRate + '</strong>' +
            '<br><strong>Original rate: $' + this.project.connectionRate + '</strong>' +
            '<br><strong>Total Units:</strong> ' + this.units +
            '</p><p>To review this thread request, click here: <a href="' + projectURL + '">' + this.project.name + '</a></p>'

          var usersNotification = []
          this.usersByLicenseeOfficeId.map(u => {
            if (u.hasJfeAdminPermission) {
              usersNotification.push(u)
            }
          })

          this.sendMultipleEmails(subject, body, usersNotification)
        } catch (e) {
          console.error(e)
        } finally {
          loadingComponent.close()
          this.$buefy.toast.open('Sent notifications')
        }
      }
    },
    async rejectRequestNewRate () {
      this.project.connectionNewRateStatus = 3 // Rejected
      this.project = await ProjectsApi.updateProject(this.project.id, this.project)
      //= ==========================================
      // SEND NOTIFICATIONS
      //= ==========================================
      const loadingComponent = this.$buefy.loading.open({ container: null })
      try {
        const projectURL = process.env.VUE_APP_WEB_BASE + '/royalty_tracker/project/' + this.type + '/' + this.project.id
        const subject = 'Rejected: New rate requested for project ' + this.project.name
        const body = 'Your rate request was denied:' +
          '<p><strong>Project:</strong>' + this.project.name +
          '<br><strong>Requested rate: $' + this.project.connectionNewRate + '</strong>' +
          '<br><strong>Original rate: $' + this.project.connectionRate + '</strong>' +
          '<br><strong>Total Units:</strong> ' + this.units +
          '</p><p>To review this thread request, click here: <a href="' + projectURL + '">' + this.project.name + '</a></p>'

        var usersNotification = []
        this.usersByLicenseeId.map(u => {
          if (u.hasJfeAdminPermission) {
            usersNotification.push(u)
          }
        })

        this.sendMultipleEmails(subject, body, usersNotification)
      } catch (e) {
        console.error(e)
      } finally {
        loadingComponent.close()
        this.$buefy.toast.open('Sent notifications')
      }
    },
    async save () {
      if (await this.$validator.validateAll()) {
        this.project.royalty = this.royalty
        this.project.total = this.total
        this.project.connectionRate = this.rate
        if (this.newProject) {
          this.project.connectionWeight = this.weightById[this.selectedWeight].weight
        }

        if (this.id === 'new') {
          this.project.type = this.type

          if (this.type === 'accessory') {
            this.project.status = 'accepted'
          }

          if (this.profile.licenseeId && !this.profile.officeId) {
            this.project.licenseeId = this.profile.licenseeId
          }
          this.project = await ProjectsApi.createProject(this.project)
          // Create Hex Order Number Updated. The first one could be lose
          this.project.orderNumber = this.projectLicensee.code + '-' + this.convertBase(this.project.id + this.fatorNumber).toUpperCase()
          // Next will update the order number
        }

        let projectId = this.id === 'new' ? this.project.id : this.id

        try {
          this.project = await ProjectsApi.updateProject(projectId, this.project)
          this.$buefy.toast.open('Project saved')
        } catch (error) {
          this.$buefy.dialog.alert({
            title: 'Ouch!',
            message: `<strong>Status</strong> ${error.response.status}<br><strong>Message:</strong> ${error.response.data.message}. <p>Please review this project and make sure it is consistent.</p>`,
            type: 'is-danger',
            hasIcon: true,
            icon: 'exclamation-circle'
          })
          return false
        }

        //= ==========================================
        // SEND NOTIFICATIONS
        //= ==========================================
        const loadingComponent = this.$buefy.loading.open({ container: null })
        try {
          if (this.type !== 'accessory') {
            if (this.id === 'new') { // Send email new request for the USER OFFICE
              const projectURL = process.env.VUE_APP_WEB_BASE + '/royalty_tracker/project/' + this.type + '/' + this.project.id
              const subject = 'New thread request: ' + this.licensee.name
              const body = this.licensee.name + ' has submitted a new thread request:' +
                '<p><strong>Project:</strong> ' + this.project.name +
                '<br><strong>Connection:</strong> ' + this.projectConnection.name +
                '<br><strong>Size:</strong> ' + this.projectConnectionSize.size +
                '<br><strong>Weight:</strong> ' + this.weightById[this.selectedWeight].weight + '#' +
                '<br><strong>Material:</strong> ' + this.materialsType.find(m => m.type === this.project.materialType).name +
                '<br><strong>Total Units:</strong> ' + this.units +
                '</p><p>To review this thread request, click here: <a href="' + projectURL + '">' + this.project.name + '</a></p>'

              var usersNotification = [{ id: 0, username: process.env.VUE_APP_EMAIL_GENERAL_JFE, firstName: 'JFE', lastName: 'Notifications' }]
              this.usersByLicenseeOfficeId.map(u => {
                if (u.hasJfeAdminPermission) {
                  usersNotification.push(u)
                }
              })
              for (const user of usersNotification) {
                await this.sendEmailToUser(subject, body, user).then((mailResult) => {
                  if (mailResult.Status !== undefined) {
                    if (mailResult.Status === 'success') {
                      // Do something?
                    } else {
                      console.log('#201 Error sending email:' + mailResult.ErrorMessage)
                    }
                  } else {
                    console.log('#202 Error sending email:' + JSON.stringify(mailResult.ErrorMessage))
                  }
                })
              }
              this.$router.push({ name: 'royalty_tracker_projects' })
              // window.close()
            } else { // It is not new and not have sent the notification
              if ((this.project.status === 'accepted') || (this.project.status === 'rejected')) {
                if ((!this.project.sentNotification) || (this.oldProjectStatus !== this.project.status)) {
                  this.openSendNotificationModal(this.project.status)
                } else {
                  this.$router.push({ name: 'royalty_tracker_projects' })
                  // window.close()
                }
              } else {
                this.$router.push({ name: 'royalty_tracker_projects' })
                // window.close()
              }
            }
          } else {
            this.$router.push({ name: 'royalty_tracker_projects' })
            // window.close()
          }
        } catch (e) {
          console.error(e)
        } finally {
          loadingComponent.close()
        }
      }
    },
    async sendEmailToUser (subject, body, user) {
      this.notificationEmail.emailTo.email = user.username
      this.notificationEmail.emailTo.name = user.firstName + ' ' + user.lastName
      this.notificationEmail.subject = subject
      this.notificationEmail.body = body
      this.notificationEmail.textPart = null

      return EmailsApi.sendEmail(this.notificationEmail)
    },
    async changeStatusAfterNotification () {
      this.project = await ProjectsApi.updateProject(this.id, this.project)
      this.$router.push({ name: 'royalty_tracker_projects' })
    },
    async cancelRequest () {
      // this.project.status = 'canceled'
      // this.project = await ProjectsApi.updateProject(this.id, this.project)
      // this.$router.push({ name: 'royalty_tracker_projects' })
      this.confirmCancelProject()
    },
    async refreshProjectConnection () {
      if (this.project.connectionId) {
        this.projectConnection = await AdminApi.getConnectionById(this.project.connectionId)
      } else {
        this.projectConnection = null
      }
      this.setPinBoxVisibility()
    },
    confirmDeleteProject (project) {
      this.$buefy.dialog.confirm({
        message:
          'Are you sure you want to delete this project?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await ProjectsApi.deleteProject(this.$route.params.id)
          this.$buefy.toast.open('Project deleted')
          this.$router.push({ name: 'royalty_tracker_projects' })
        }
      })
    },
    confirmCancelProject () {
      this.$buefy.dialog.confirm({
        message:
          'Are you sure you want to cancel this project?',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await ProjectsApi.cancelProject(this.$route.params.id)
          this.$buefy.toast.open('Project canceled')
          this.$router.push({ name: 'royalty_tracker_projects' })
        }
      })
    },
    async duplicateProject (project) {
      this.newProject = true
      this.project.id = null
      this.project.name += ' Copy'
      if (this.type === 'accessory') {
        this.project.status = 'accepted'
      } else {
        this.project.status = null
      }
      this.project.connectionRate = null
      this.project.invoiceId = null
      this.project.total = null

      // Preview Generate Order Number
      var lastProject = await ProjectsApi.getLastProjectID()
      this.project.orderNumber = this.projectLicensee.code + '-' + this.convertBase((lastProject.id + 1) + this.fatorNumber).toUpperCase()

      // this.project = await ProjectsApi.createProject(this.project)
      this.$router.push({
        name: 'royalty_tracker_project',
        params: { id: 'new', type: this.project.type }
      })
    },
    convertBase (num) {
      // Code from https://gist.github.com/faisalman/4213592
      // decimal to hexadecimal
      var ConvertBase = function (num) {
        return {
          from: function (baseFrom) {
            return {
              to: function (baseTo) {
                return parseInt(num, baseFrom).toString(baseTo)
              }
            }
          }
        }
      }
      return ConvertBase(num).from(10).to(16)
    },
    goToInvoice () {
      const routeData = this.$router.resolve({
        name: 'royalty_tracker_invoice',
        params: {
          invoiceId: this.project.invoiceId,
          licenseeId: this.project.licenseeId
        }
      })
      window.open(routeData.href, '_blank')
    },
    openSendNotificationModal (status) {
      var usersNotification = []
      this.usersByLicenseeId.map(u => {
        if (u.hasLicenseeRoyaltyTrackerPermission) {
          usersNotification.push(u)
        }
      })

      if (this.project.contactEmail) {
        const userContactProject = usersNotification.find(user => user.username === this.project.contactEmail)

        if (!userContactProject) {
          usersNotification.push({ id: 'projectUser', username: this.project.contactEmail, firstName: this.project.contactName, lastName: '' })
        }
      }

      // JFE notificatioin
      // usersNotification.push({ id: 0, username: process.env.VUE_APP_EMAIL_GENERAL_JFE, firstName: 'JFE', lastName: 'Notifications' })

      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: SendNotificationModal,
        props: {
          project: this.project,
          userRecipients: usersNotification,
          typeEmail: status
        },
        events: {
          sent: async (response) => {
            if (response.sent) {
              this.project.sentNotification = true
              this.project.sentNotificationDate = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
              this.changeStatusAfterNotification()
            }
          },
          close: async (response) => {
            if (response.sent) {
              this.$router.push({ name: 'royalty_tracker_projects' })
            }
          }
        }
      })
    }
  }
}

</script>
<style lang="scss">
.hover-rate {
  &:hover {
    background: #ffffc3;
    cursor: pointer;
  }
}
</style>
